.top-title {
  background-image: url("/images/header_line.png");
}
#contents.top {
  margin: 10px 0 0 10px;
  width: 100%;
}
.bg-login {
  display: block;
  position: absolute;
  bottom: 30px;
  width: 100%;
}
#login-box {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 460px;
  top: 25%;
  left: 50%;
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  margin-top: 100px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
#login-box .btn {
  width: 90%;
  padding: 5px 0;
  margin: 10px 5% 10px;
  font-size: 18px;
}
#login-box .description {
  color: #ccc;
  font-size: 12px;
  margin: 0 5% 20px;
}
#login-box .error-msg {
  color: red;
  font-size: 17px;
  margin: 0 5% 20px;
}
.login-title {
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  color: #fff;
  font-size: 15px;
  line-height: 1em;
  font-weight: normal;
  padding: 15px 15px 12px 35px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  margin: 3px 3px 20px;
}
.danger {
  margin: 0 5%;
  color: #c50000;
  font-weight: bold;
  font-size: 12px;
}
.form-group {
  width: 90%;
  margin: 10px 5%;
}
.form-group label {
  color: #ccc;
  font-size: 12px;
}
.form-group .form-control {
  width: 98%;
  padding: 5px 1%;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.login-btn-wrap {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
#footer {
  clear: both;
  font-size: 0.9em;
  padding: 5px;
  text-align: center;
  background-color: #F8F8F8;
  border-top: 1px solid #E0E0E0;
  color: #636363;
  margin-top: 45px;
  bottom: 0;
  width: 100%;
  position: absolute;
}

/*# sourceMappingURL=login.css.map */
